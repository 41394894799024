/* eslint-disable react/jsx-key */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { graphql, navigate } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Layout from '../components/shared/layout';
import SEO from '../components/shared/seo';
import Tag from '../components/shared/tag/tag';

const GrowingPage = props => {
  const { data, location } = props;
  const [windowWidth, setWindowWidth] = React.useState(0);
  const siteTitle = data.site.siteMetadata.title;
  const posts = data.allMarkdownRemark.edges;

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  // Small breakpoint: 'sm': '640px',
  React.useEffect(() => {
    setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  return (
    <Layout>
      <SEO title="Growing Posts" path={location.pathname} description="Posts tagged with 'Growing'" />
      <div className="text-2xl my-12 font-light text-center">Growing Posts</div>
      {windowWidth > 639 ? (
        <div className="flex flex-col space-y-4 max-w-xl m-auto">
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug;
            return (
              <div
                role="button"
                className="h-full w-full flex flex-row space-x-2 cursor-pointer"
                onClick={() => {
                  navigate(node.fields.slug);
                }}
                onKeyPress={() => {
                  navigate(node.fields.slug);
                }}
                tabIndex={0}
              >
                <div>
                  <Img
                    className="rounded-md"
                    style={{ height: '12em', width: '16em' }}
                    objectFit="contain"
                    fluid={node.frontmatter.featuredImage.childImageSharp.fluid}
                  />
                </div>
                <div className="relative">
                  <div className="flex flex-row space-x-4 justify-start items-center">
                    {node.frontmatter.tags.map(text => (
                      <Tag text={text} />
                    ))}
                  </div>
                  <div className="text-sm font-medium my-2">{title}</div>
                  <div
                    className="font-light text-xs mt-4 leading-5"
                    dangerouslySetInnerHTML={{
                      __html: node.frontmatter.description || node.excerpt
                    }}
                  />
                  <div className="absolute bottom-2 w-full">
                    <div className="flex flex-row space-x-4 justify-start items-center">
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="h-4 w-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                        />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="h-4 w-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                        />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="h-4 w-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                        />
                      </svg> */}
                      <div className="ml-auto text-xs font-thin">
                        {node.frontmatter.date}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="flex flex-col space-y-4">
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug;
            return (
              <div
                className="cursor-pointer relative"
                onClick={() => {
                  navigate(node.fields.slug);
                }}
                onKeyPress={() => {
                  navigate(node.fields.slug);
                }}
                tabIndex={0}
                label={node.fields.slug}
                role="button"
              >
                <Img
                  className="rounded-md h-48"
                  objectFit="contain"
                  fluid={node.frontmatter.featuredImage.childImageSharp.fluid}
                />
                <div className="absolute w-full h-full bottom-0 bg-black opacity-50 rounded-md" />
                <div className="absolute top-0 p-4">
                  <div className="flex flex-row space-x-4 justify-start items-center">
                    {node.frontmatter.tags.map(text => (
                      <Tag text={text} />
                    ))}
                  </div>
                  <div className="text-sm font-medium my-2 text-white">
                    {title}
                  </div>
                  <div
                    className="font-light text-white text-xs mt-4 leading-5"
                    dangerouslySetInnerHTML={{
                      __html: node.frontmatter.description || node.excerpt
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </Layout>
  );
};

GrowingPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.string.isRequired
};

export default GrowingPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    hb: file(relativePath: { eq: "hb-1.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
            featuredImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
